
import Vue, { PropType } from 'vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { compressImage } from '@/util-functions/image-utils';
import { t } from '@/util-functions/language-utils';
import { showLoading, hideLoading } from '@/util-functions/loading-utils';
import { generateId, showError } from '@/util-functions/misc-firestore-utils';
import { showNotice } from '@/util-functions/notice-utils';
import validationRules from '@/validation-rules';

enum eActionType {
  update,
  add,
}

export default Vue.extend({
  components: { VueCropper },
  props: {
    value: {
      type: Array as PropType<UploadedImage[]>,
      default: () => [],
    },
    imageSizes: {
      type: Object as PropType<ImageSizes>,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data(): {
    rules: typeof validationRules;
    localImages: UploadedImage[];
    filteredLocalImages: UploadedImage[];
    currentSlideIndex: number;
    showEditImagesDialog: boolean;
    showImageCropDialog: boolean;
    actionType: eActionType;
    nameOfImageBeingCropped: string;
    caption: string;
  } {
    return {
      rules: validationRules,
      localImages: [],
      filteredLocalImages: [], // The filtered local images array is the localImages array sans deleted items.
      currentSlideIndex: 0,
      showEditImagesDialog: false,
      showImageCropDialog: false,
      actionType: eActionType.add,
      nameOfImageBeingCropped: '',
      caption: '',
    };
  },
  watch: {
    value() {
      this.onLoad();
    },
  },
  mounted() {
    this.onLoad();
  },
  computed: {
    currentImage(): UploadedImage {
      return this.localImages[this.getCurrentImageIndexInLocalImagesArray()] || {};
    },
  },
  methods: {
    onCancelUpload() {
      this.showImageCropDialog = false;
    },
    reset() {
      (this.$refs.cropper as any).reset();
    },
    rotate(deg: number) {
      (this.$refs.cropper as any).rotate(deg);
    },
    zoom(zoom: number) {
      (this.$refs.cropper as any).relativeZoom (zoom);
    },
    // Don't move the image in the filtered array used by the carousel itself, the localImages array is the important array to change.
    moveUp() {
      const indexForitemToMove = this.getCurrentImageIndexInLocalImagesArray();
      const itemToMove = this.localImages[indexForitemToMove];
      this.localImages[indexForitemToMove] = this.localImages[indexForitemToMove - 1];
      this.localImages[indexForitemToMove - 1] = itemToMove;
      this.setFilteredLocalImages();
      this.$emit('input', this.localImages);
    },
    moveDown() {
      const indexForitemToMove = this.getCurrentImageIndexInLocalImagesArray();
      const itemToMove = this.localImages[indexForitemToMove];
      this.localImages[indexForitemToMove] = this.localImages[indexForitemToMove + 1];
      this.localImages[indexForitemToMove + 1] = itemToMove;
      this.setFilteredLocalImages();
      this.$emit('input', this.localImages);
    },
    currImageCaption(): string {
      return this.currentImage.caption || '';
    },
    updateCaptionForCurrentImage(val: string) {
      this.currentImage.caption = val;
      this.caption = '';
    },
    getCurrentImageIndexInLocalImagesArray(): number {
      const currentImageId = this.filteredLocalImages?.[this.currentSlideIndex]?.id;
      const imageIds = this.localImages.map((image: UploadedImage) => {
        return image.id;
      });
      const index = imageIds.indexOf(currentImageId);
      return index > 0 ? index : 0;
    },
    setFilteredLocalImages(scrollToTheEnd = false) {
      this.filteredLocalImages = this.localImages.filter((image: UploadedImage) => {
        return !image.markedForDeletion;
      });
      if (this.currentSlideIndex > this.filteredLocalImages.length || scrollToTheEnd) {
        this.currentSlideIndex = this.filteredLocalImages.length - 1;
      }
    },
    onLoad() {
      this.localImages = this.value;
      this.setFilteredLocalImages();
    },
    deleteImage() {
      const image = this.localImages[this.getCurrentImageIndexInLocalImagesArray()];
      image.markedForDeletion = true;
      image.toBeUploaded = false;
      image.smallImageBase64Preview = '';
      image.largeImageBase64Preview = '';
      image.smallCompressedImage = null;
      image.largeCompressedImage = null;
      this.setFilteredLocalImages();
      this.$forceUpdate();
    },
    onUpdateImage() {
      this.actionType = eActionType.update;
      this.openFileInput();
    },
    onAddImage() {
      this.actionType = eActionType.add;
      this.openFileInput();
    },
    onNewUpload(event: any) {
      const image: File | null = event?.target?.files?.[0] || null;
      (this.$refs.fileInput as HTMLInputElement).value = '';

      if (!image) {
        return;
      }

      if (!image.type.includes('image/')) {
        alert('Please select an image file');
        return;
      }

      const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      toBase64(image).then((base64: string | ArrayBuffer | null) => {
        this.showImageCropDialog = true;
        Vue.nextTick(() => {
          (this.$refs.cropper as any).replace(base64);
          this.nameOfImageBeingCropped = image.name;
        });
      });
    },
    onFinishedCropping() {
      this.showImageCropDialog = false;
      let isWebP = false;
      // Get cropeed image as base64 string.
      let base64ImageData: string = (this.$refs.cropper as any).getCroppedCanvas().toDataURL();

      const dataURLtoFile = (dataurl: string) => {
        const arr = dataurl.split(',');
        const mimeType = arr[0]?.match(/:(.*?);/)?.[1];
        const bstr = window.atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        const fileName = this.nameOfImageBeingCropped;
        this.nameOfImageBeingCropped = '';

        isWebP = mimeType?.includes('webp') || false;
        const uncompressedFile = new File([u8arr], fileName, { type: mimeType });
        return uncompressedFile;
      };

      const onWebPImageReadyOrConversionFails = () => {
        hideLoading();

        // Convert base64 string to a file.
        const imageFileBeforeRename = dataURLtoFile(base64ImageData);
        const oldFileNameExt = imageFileBeforeRename.name.split('.').at(-1);
        const mimeType = imageFileBeforeRename.type;
        const isWebPAfterConvertAttempt = mimeType?.includes('webp') || false;
        const imageFile = new File([imageFileBeforeRename], `${generateId()}.${isWebPAfterConvertAttempt ? 'webp' : oldFileNameExt}`, { type: mimeType });

        let newImage: UploadedImage = {
          id: generateId(),
          caption: '',
        };

        // Compress the file.
        const promiseArray: Promise<any>[] = [];
        if (imageFile) {
          showLoading();
          (Object.entries(this.imageSizes) as [string, number][]).forEach(([size, value]) => {
            const imageCompressionPromise = compressImage(imageFile, value)
              .then((compressedFileObj) => {
                if (size === 'small') {
                  newImage.smallImageBase64Preview = compressedFileObj.base64data;
                  newImage.smallCompressedImage = compressedFileObj.compressedFile;
                } else {
                  newImage.largeImageBase64Preview = compressedFileObj.base64data;
                  newImage.largeCompressedImage = compressedFileObj.compressedFile;
                }
                newImage.toBeUploaded = true;

                // Reset markedForDeletion in case this image was deleted before the new image was uploaded.
                newImage.markedForDeletion = false;
              })
              .catch((error: any) => {
                showError(`Image compression failed.`, error, true);
                hideLoading();
              });
            promiseArray.push(imageCompressionPromise);
          });
          // After all images have been compressed.
          Promise.all(promiseArray).then(() => {
            switch (this.actionType) {
              case eActionType.update:
                {
                  const currentImage = this.localImages[this.getCurrentImageIndexInLocalImagesArray()] || {};

                  // Update the old image with the following values:
                  // smallImageBase64Preview
                  // smallCompressedImage
                  // largeImageBase64Preview
                  // largeCompressedImage

                  this.localImages[this.getCurrentImageIndexInLocalImagesArray()] = {
                    ...currentImage,
                    ...newImage,
                  };
                  this.setFilteredLocalImages();
                }
                break;
              case eActionType.add:
                this.localImages.push(newImage);
                this.setFilteredLocalImages(true);
                showNotice(t.imageAdded);
                break;
            }
            this.$forceUpdate();
            this.$emit('input', this.localImages);
            this.$emit('onUpload', this.localImages)            
            hideLoading();
          });
        }
      };

      // Converting the image to webP if it isn't already.
      if (!isWebP) {
        showLoading();
        // Create a new Image object
        const img = new Image();

        // Set the source of the image to the Base64-encoded PNG
        img.src = base64ImageData;

        // Get the canvas element
        const canvas = document.getElementById('canvas') as HTMLCanvasElement | null;

        if (!canvas) {
          onWebPImageReadyOrConversionFails();
          return;
        }

        const ctx = canvas.getContext('2d');

        if (!ctx) {
          return;
        }

        // When the image has loaded, draw it onto the canvas and convert it to WebP
        img.onload = () => {
          // Set the dimensions of the canvas to match the image.
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0);

          // Convert the canvas to a Base64-encoded WebP image
          canvas.toBlob(
            (blob) => {
              if (!blob) {
                onWebPImageReadyOrConversionFails();
                return;
              }
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                const base64WebP = reader.result;
                if (base64WebP) {
                  base64ImageData = base64WebP as string;
                }
                onWebPImageReadyOrConversionFails();
              };
            },
            'image/webp',
            1
          );
        };
      } else {
        onWebPImageReadyOrConversionFails();
      }
    },
    onEditOrUploadClick() {
      if (this.filteredLocalImages.length) {
        this.showEditImagesDialog = true;
      } else {
        this.onAddImage();
      }
    },
    openFileInput() {
      (this.$refs.fileInput as HTMLInputElement).click();
    },
  },
});
